<template>
  <div id="login">
    <div class="login-page">
      <div class="container">
        <div>
          <div class="col-lg-4 col-md-6 col-sm-8 mx-auto">
            <div
              v-if="!registerActive"
              class="card login"
              v-bind:class="{ error: emptyFields }"
            >
              <h1>Login</h1>
              <form class="form-group">
                <input
                  v-model="user.username"
                  type="text"
                  class="form-control"
                  placeholder="Username"
                  required
                />
                <input
                  v-model="user.password"
                  type="password"
                  class="form-control"
                  placeholder="Passwort"
                  required
                />
                <input
                  type="submit"
                  class="btn btn-primary loginButton"
                  @click.prevent="doLogin"
                  value="Login"
                />
                <b-form-checkbox
                  v-model="stayLoggedIn"
                  class="stayLoggedInCheckbox"
                >
                  Angemeldet bleiben
                </b-form-checkbox>
                <p>
                  Passwort vergessen?
                  <a href="#" @click="resetPassword">Hier zurücksetzen</a>
                </p>
                <p>
                  Kein Benutzer?
                  <a
                    href="#"
                    @click="
                      (registerActive = !registerActive), (emptyFields = false)
                    "
                    >Hier erstellen</a
                  >
                </p>
              </form>
            </div>

            <div
              v-else
              class="card register"
              v-bind:class="{ error: emptyFields }"
            >
              <h1>Registrierung</h1>
              <form class="form-group">
                <input
                  v-model="newUser.username"
                  type="text"
                  class="form-control"
                  placeholder="Username"
                  required
                />
                <input
                  v-model="newUser.email"
                  type="email"
                  class="form-control"
                  placeholder="E-Mail"
                  required
                />
                <input
                  v-model="newUser.password"
                  type="password"
                  class="form-control"
                  placeholder="Passwort"
                  required
                />
                <input
                  v-model="confirmedPassword"
                  type="password"
                  class="form-control"
                  placeholder="Passwort bestätigen"
                  required
                />
                <input
                  type="submit"
                  class="btn btn-primary loginButton"
                  @click.prevent="doRegister"
                  value="Registrieren"
                />
                <p>
                  Bereits angemeldet?
                  <a
                    href="#"
                    @click="
                      (registerActive = !registerActive), (emptyFields = false)
                    "
                    >Hier anmelden</a
                  >
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="loginFailedModal"
      centered
      ok-only
      title="Fehler"
      header-bg-variant="danger"
      ok-variant="warning"
      footer-bg-variant="danger"
      body-bg-variant="danger"
      footer-border-variant="light"
      header-border-variant="light"
    >
      Benutzername oder Passwort falsch!
    </b-modal>
    <b-modal
      id="registerFailedModal"
      centered
      ok-only
      title="Fehler"
      header-bg-variant="danger"
      ok-variant="warning"
      footer-bg-variant="danger"
      body-bg-variant="danger"
      footer-border-variant="light"
      header-border-variant="light"
    >
      Machen Sie richtige Angaben!
    </b-modal>
    <b-modal
      id="requestPasswordModalSuccess"
      centered
      ok-only
      title="Erfolgreich"
      header-bg-variant="success"
      ok-variant="primary"
      footer-bg-variant="success"
      body-bg-variant="success"
      footer-border-variant="light"
      header-border-variant="light"
    >
      Der Link zur Zurücksetzung des Passworts wurde an Ihre Email gesendet.
    </b-modal>
    <b-modal
      id="requestPasswordModalError"
      centered
      ok-only
      title="Fehler"
      header-bg-variant="danger"
      ok-variant="warning"
      footer-bg-variant="danger"
      body-bg-variant="danger"
      footer-border-variant="light"
      header-border-variant="light"
    >
      Fehler beim Zugriff zur Datenbank.
    </b-modal>
  </div>
</template>

<script>
import User from "../models/user";
import dataService from "../services/data.service";
import DataService from "../services/data.service";
export default {
  data() {
    return {
      registerActive: false,
      emptyFields: false,
      user: new User(),
      newUser: new User(),
      confirmedPassword: "",
      stayLoggedIn: false,
    };
  },
  methods: {
    doLogin() {
      if (this.user.username === "" || this.user.password === "") {
        this.emptyFields = true;
      } else {
        this.user.balance = 0;
        let userInfo = {
          user: this.user,
          stayLoggedIn: this.stayLoggedIn,
        };
        DataService.login(userInfo).then((response) => {
          if (response === "Error") {
            this.$bvModal.show("loginFailedModal");
          } else {
            this.$router.push("/Home");
            window.location.reload();
          }
        });
      }
    },
    doRegister() {
      if (
        this.newUser.username === "" ||
        this.newUser.email === "" ||
        this.newUser.password === "" ||
        this.confirmedPassword === ""
      ) {
        this.emptyFields = true;
      } else {
        this.newUser.balance = 0;
        DataService.register(this.newUser).then((response) => {
          if (response) {
            this.registerActive = false;
          } else {
            this.$bvModal.show("registerFailedModal");
          }
        });
      }
    },
    resetPassword() {
      if (this.user.username) {
        dataService.requestPassword(this.user.username).then((response) => {
          if (response.success) {
            this.$bvModal.show("requestPasswordModalSuccess");
          } else {
            this.$bvModal.show("requestPasswordModalError");
          }
        });
      }
    },
  },
  computed: {
    loggedIn() {
      return DataService.checkLoggedIn();
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/Home");
    }
  },
};
</script>

<style scoped>
p {
  line-height: 1.5rem;
}

.card {
  padding: 25px;
  background-color: black;
  color: white;
  opacity: 0.9;
}
.form-group {
  margin-bottom: 0;
}
.form-group input {
  margin-bottom: 20px;
}

.login-page {
  align-items: center;
  display: flex;
  margin-top: 10vh;
}

.loginButton {
  display: block;
  margin: 0 auto;
}

h1 {
  margin-bottom: 1.5rem;
  text-align: center;
  white-space: nowrap;
}

.stayLoggedInCheckbox {
  margin-bottom: 20px;
}
</style>
